import { defineStore } from 'pinia';
import { Storage } from '@ionic/storage';
import { ref } from 'vue';
import { AuthData, useTokenStore } from '../token';
import { useAPIClientStore } from '../apiClient';
import type { Utilisateur } from './data';

export const useAuthStore = defineStore('auth', () => {
  // Storage
  const storage = new Storage();

  // Token
  const tokenStore = useTokenStore();
  const { request } = useAPIClientStore();

  // States
  const utilisateur = ref<any>();

  // Actions
  async function signIn(credentials: { email: string; password: string }) {
    try {
      const response = await request<{ data: Utilisateur }>({
        method: 'POST',
        url: '/auth/sign_in',
        headers: { ...credentials },
      });
      const authData = AuthData.fromHTTPHeaders(response.headers);
      await tokenStore.save(authData);
      await sauveUtilisateur(response.data);
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('erreur à la connexion', error);

      return false;
    }
  }

  async function signOut() {
    try {
      await tokenStore.clear();
      await reset();
      return true;
    } catch {
      return false;
    }
  }

  async function recupereUtilisateur() {
    await storage.create();
    const result = (await storage.get('utilisateur')) as string;
    utilisateur.value = JSON.parse(result) as Utilisateur;
  }

  async function sauveUtilisateur(utilisateurData: { data: Utilisateur }) {
    await storage.create();
    utilisateur.value = utilisateurData.data;
    await storage.set('utilisateur', JSON.stringify(utilisateur.value));
  }

  async function migreUtilisateur() {
    const emailLocal = localStorage.getItem('email');
    const accessToken = localStorage.getItem('accessToken');

    if (emailLocal != null) {
      await sauveUtilisateur({
        data: {
          civilite: localStorage.getItem('civilite') ?? null,
          email: emailLocal,
          nom: localStorage.getItem('nom') ?? null,
          prenom: localStorage.getItem('prenom') ?? null,
          mobile: localStorage.getItem('mobile') ?? null,
        },
      });

      localStorage.removeItem('email');
      localStorage.removeItem('civilite');
      localStorage.removeItem('nom');
      localStorage.removeItem('prenom');
      localStorage.removeItem('mobile');
    }

    if (accessToken != null) {
      await tokenStore.save({
        accessToken: localStorage.getItem('accessToken') ?? undefined,
        client: localStorage.getItem('client') ?? undefined,
        expiry: localStorage.getItem('expiry') ?? undefined,
        uid: localStorage.getItem('uid') ?? undefined,
        tokenType: localStorage.getItem('tokenType') ?? undefined,
      });

      localStorage.removeItem('accessToken');
      localStorage.removeItem('client');
      localStorage.removeItem('expiry');
      localStorage.removeItem('uid');
      localStorage.removeItem('tokenType');
    }

    await recupereUtilisateur();
  }

  async function reset() {
    utilisateur.value = undefined;
    await storage.create();
    await storage.remove('utilisateur');
  }

  return {
    utilisateur,
    signIn,
    signOut,
    recupereUtilisateur,
    migreUtilisateur,
  };
});
