/* eslint-disable @typescript-eslint/naming-convention */
import { type AuthData } from '../token';

export interface APICredentials {
  // readonly authorization: string | null | undefined;
  readonly 'access-token': string | null | undefined;
  readonly client: string | null | undefined;
  readonly expiry: string | null | undefined;
  readonly uid: string | null | undefined;
  readonly 'token-type': string | null | undefined;
}

export const APICredentials = {
  fromAuthToken(authToken: AuthData): APICredentials {
    return {
      // authorization: authToken.authorization,
      'access-token': authToken.accessToken,
      client: authToken.client,
      expiry: authToken.expiry,
      uid: authToken.uid,
      'token-type': authToken.tokenType,
    };
  },
};

export interface APIClient {
  /**
   * Environment variable
   */
  readonly env: string;
  /**
   * Base API URL to vera server
   */
  readonly baseURL: string;
  /**
   * Auth credentials
   */
  readonly getCredentials: () => APICredentials | undefined;
  /**
   * Callback when new access token was received from API
   */
  readonly onRefreshToken: (accessToken: string) => void;
  /**
   * Callback when access token sent is expired
   */
  readonly onExpireToken: (accessToken: string) => void;
}

/**
 * APIClient constructor
 *
 * @param parameters
 */
export function APIClient(parameters: APIClient.Parameters): APIClient {
  const { credentials, onRefreshToken = () => {}, onExpireToken = () => {} } = parameters;
  const envOrObject = parameters.env ?? 'production';
  const env = typeof envOrObject === 'string' ? envOrObject : envOrObject.name;
  const baseURL = typeof envOrObject === 'string' ? APIClient.defaultBaseURL[envOrObject] : envOrObject.baseURL;
  return {
    env,
    baseURL,
    getCredentials: typeof credentials === 'function' ? credentials : () => credentials,
    onRefreshToken,
    onExpireToken,
  };
}
APIClient.defaultBaseURL = {
  production: 'https://adhesion.lacompagniedesfamilles.com/',
  staging: 'https://staging.compagnie-des-familles.captive.dev/',
};

export namespace APIClient {
  /**
   * Environment name
   */
  export type EnvironmentName = 'production' | 'staging';

  /**
   * Client constructor parameters
   */
  export type Parameters = {
    /**
     * Environment
     */
    env?:
      | EnvironmentName
      | {
          /**
           * Base API URL to vera server
           *
           * @example
           * ```ts
           * 'https://staging.vera.captive.dev/api/' // Sans la version
           * ```
           */
          baseURL: string;
          /**
           * Nom de l'environnement
           *
           * @example
           * ```ts
           * 'my_environment'
           * ```
           */
          name: string;
        };
    /**
     * Credentials (token) getter
     */
    credentials?: APICredentials | (() => APICredentials);
    /**
     * Callback when new access token was received from API
     */
    onRefreshToken?: APIClient['onRefreshToken'];
    /**
     * Callback when access token sent is expired
     */
    onExpireToken?: APIClient['onExpireToken'];
  };
}
