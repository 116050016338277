import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useAPIClientStore } from '../apiClient';
import { isGardeError, type Garde } from './data';

export const useGardeStore = defineStore('garde', () => {
  const { request } = useAPIClientStore();

  // States
  const historiques = ref<Garde[]>([]);
  const garde = ref<Garde | null | string>();

  // Getters
  const enCours = computed(() => typeof garde.value === 'object' && garde.value?.etat !== 'terminee');

  // Actions
  async function recupereGarde() {
    try {
      const response = await request<Garde | null>({ method: 'GET', url: '/garde' });
      garde.value = response.data;
      return true;
    } catch {
      return false;
    }
  }

  async function recupereGardeParId(id: number) {
    try {
      const response = await request<Garde>({ method: 'GET', url: `/gardes/${id}` });
      return { success: true, garde: response.data };
    } catch {
      return { success: false };
    }
  }

  async function recupereHistorique() {
    try {
      const response = await request<Garde[]>({ method: 'GET', url: '/gardes' });
      historiques.value = response.data.filter((g) => g.etat === 'terminee');
      return true;
    } catch {
      return false;
    }
  }

  async function creeGarde(badge: string, coords: { latitude: number; longitude: number }) {
    try {
      const result = await request<Garde>({
        method: 'POST',
        url: '/gardes',
        data: {
          badge,
          latitude: coords.latitude,
          longitude: coords.longitude,
        },
      });
      garde.value = null;
      return { success: true, garde: result.data };
    } catch (error) {
      if (isGardeError(error)) return { success: false, error: error.response?.data };
      return { success: false };
    }
  }

  async function termineGarde(badge: string, coords: { latitude: number; longitude: number }) {
    try {
      const result = await request<Garde>({
        method: 'PATCH',
        url: '/gardes',
        data: {
          badge,
          latitude: coords.latitude,
          longitude: coords.longitude,
        },
      });
      garde.value = null;
      return { success: true, garde: result.data };
    } catch (error) {
      if (isGardeError(error)) return { success: false, error: error.response?.data };
      return { success: false };
    }
  }

  async function declareKilometres(gardeId: number, kilometresEffectues: string) {
    const data = {
      id: gardeId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      kilometres_effectues: kilometresEffectues,
    };

    try {
      const response = await request<Garde>({ method: 'PATCH', url: '/gardes', data });
      return { success: true, data: response.data };
    } catch (error) {
      if (isGardeError(error)) return { success: false, error: error.response?.data };
      return { success: false };
    }
  }

  return {
    garde,
    enCours,
    historiques,
    declareKilometres,
    recupereGarde,
    recupereGardeParId,
    recupereHistorique,
    termineGarde,
    creeGarde,
  };
});
