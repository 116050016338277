<script setup lang="ts">
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
  IonMenu,
  IonMenuToggle,
  useIonRouter,
} from '@ionic/vue';
import { ref, defineOptions } from 'vue';
import { chevronForward } from 'ionicons/icons';
import { Browser } from '@capacitor/browser';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';
import { useGardeStore } from '@/store/garde';
import './AppMenu.css';

// Name and options
const componentName = 'AppMenu';

defineOptions({
  name: componentName,
});

const router = useIonRouter();

const authStore = useAuthStore();
const { signOut } = authStore;
const gardeStore = useGardeStore();
const { enCours } = storeToRefs(gardeStore);

const selectedIndex = ref(0);
const appPages = [
  {
    title: () => (enCours.value ? 'Ma garde' : 'Nouvelle garde'),
    action: () =>
      router.navigate(
        { path: enCours.value ? '/gardes' : '/nouvelle-garde' },
        'back',
        'pop'
      ),
    icon: true,
  },
  {
    title: () => 'Historique de mes gardes',
    action: () => router.navigate({ path: '/historique' }, 'forward', 'pop'),
    icon: true,
    dataTest: 'historique',
  },
  {
    title: () => 'Mon planning',
    action: () => Browser.open({ url: 'https://v2.medisysnet.fr' }),
  },
  {
    title: () => 'Mes informations',
    action: () => router.navigate({ path: '/informations' }, 'forward', 'pop'),
    icon: true,
    dataTest: 'infos',
  },
  {
    title: () => "Contacter l'agence",
    action: () => router.navigate({ path: '/contacts' }, 'forward', 'pop'),
    icon: true,
    dataTest: 'contact',
  },
];

function deconnecte() {
  void signOut();
  router.navigate({ name: 'Connexion' }, 'back', 'pop');
}

function clickMenuItem(index: number, action: () => void) {
  selectedIndex.value = index;
  action();
}

function getVersion() {
  return import.meta.env.VITE_APP_VERSION;
}
</script>

<template>
  <ion-menu content-id="main-content" type="overlay" :class="[componentName]">
    <ion-content>
      <ion-list id="inbox-list">
        <ion-menu-toggle v-for="(p, i) in appPages" :key="i" :auto-hide="false">
          <ion-item
            lines="none"
            class="hydrated"
            :class="{ selected: selectedIndex === i }"
            :data-test="`menu-${p.dataTest}`"
            @click="clickMenuItem(i, p.action)"
          >
            <ion-icon
              v-if="p.icon"
              slot="end"
              :icon="chevronForward"
            ></ion-icon>
            <ion-label>{{ p.title() }}</ion-label>
          </ion-item>
        </ion-menu-toggle>

        <!-- Deconnexion -->
        <ion-item
          data-test="menu-deconnexion"
          lines="none"
          class="hydrated"
          @click="deconnecte"
        >
          <ion-label>Déconnexion</ion-label>
        </ion-item>
      </ion-list>

      <ion-list>
        <ion-item lines="none" class="hydrated">
          <ion-label>version : {{ getVersion() }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>
