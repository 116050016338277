import { createRouter, createWebHistory } from '@ionic/vue-router';
import { storeToRefs } from 'pinia';
import { type RouteRecordRaw } from 'vue-router';
import { useTokenStore } from '@/store/token';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/connexion',
  },
  {
    path: '/connexion',
    name: 'Connexion',
    component: () => import('../pages/Connexion/ConnexionPage.vue'),
  },
  {
    path: '/historique',
    name: 'Historique',
    component: () => import('../pages/Historique/HistoriquePage.vue'),
    meta: { requiredAuth: true },
  },
  {
    path: '/informations',
    name: 'Informations',
    component: () => import('../pages/Informations/InformationsPage.vue'),
    meta: { requiredAuth: true },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../pages/Contacts/ContactsPage.vue'),
    meta: { requiredAuth: true },
  },
  {
    path: '/gardes/:id?',
    name: 'Gardes',
    component: () => import('../pages/Gardes/GardesPage.vue'),
    meta: { requiredAuth: true },
  },
  {
    path: '/nouvelle-garde',
    name: 'NouvelleGarde',
    component: () => import('../pages/NouvelleGarde/NouvelleGardePage.vue'),
    meta: { requiredAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = useTokenStore();
  const { authenticated } = storeToRefs(token);
  await token.initialize();

  const loggedIn = authenticated.value;

  if (to.meta['requiredAuth'] === true) {
    if (loggedIn) return next();
    return next({ path: '/connexion' });
  }

  if (loggedIn) return next({ path: '/gardes' });
  return next();
});

export default router;
