/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { AxiosError } from 'axios';

/* eslint-disable @typescript-eslint/naming-convention */
export interface Garde {
  id: number;
  famille: Famille;
  created_at: string;
  dateHeureFin?: string;
  duree?: string;
  badge?: string;
  kilometres_effectues?: string;
  email_intervenant?: string;
  etat: string;
  famille_id: Famille;
  intervenant_id: number;
  updated_at: string;
  adresse_complete_debut: string;
  adresse_complete_fin: string;
  agence_id?: number | null;
  adresse_debut?: string | null;
  adresse_fin?: string | null;
  code_postal_debut?: string | null;
  code_postal_fin?: string | null;
  date_heure_fin?: string | null;
  kilometres?: number | null;
  latitude_debut?: number | null;
  latitude_fin?: number | null;
  longitude_debut?: number | null;
  longitude_fin?: number | null;
  ville_debut?: string | null;
  ville_fin?: string | null;
}

export interface Famille {
  civilite: string;
  nom: string;
  adresse: string;
  age_plus_jeune?: number;
  code_postal: string;
  created_at: string;
  email: string;
  id: number;
  mobile1: string;
  mobile2: string;
  nombre_enfants?: number;
  paje: boolean;
  prenom: string;
  tranche_revenus: string;
  transport?: string;
  updated_at: string;
  ville: string;
}

export const isGardeError = (error: any): error is AxiosError<string, any> =>
  typeof error?.response?.data === 'string' && error.response.status === 422;

export const isGardeId = (id: any): id is number =>
  (typeof id === 'string' && !Number.isNaN(Number.parseInt(id, 10))) || typeof id === 'number';

export const toGardeId = (id: number | string) => (typeof id === 'string' ? Number.parseInt(id, 10) : id);
