import { defineStore } from 'pinia';
import { computed, ref, watch, type Ref } from 'vue';
import { Preferences } from '@capacitor/preferences';
import { STORAGE_KEY, type AuthData, LOCAL_STORAGE_KEY } from './data';

export const useTokenStore = defineStore('token', () => {
  // States
  const defaultToken: AuthData = Object.freeze({
    accessToken: undefined,
    tokenType: undefined,
    client: undefined,
    expiry: undefined,
    uid: undefined,
    authorization: undefined,
  });
  const token: Ref<AuthData> = ref<AuthData>(defaultToken);
  let initialized: undefined | Promise<void>;
  const { localStorage } = globalThis;
  const Codec = {
    parse(value: string): AuthData {
      return JSON.parse(value) as AuthData;
    },
    stringify(authToken: AuthData): string {
      return JSON.stringify(authToken);
    },
  };
  const LocalStorage = {
    key() {
      return LOCAL_STORAGE_KEY;
    },
    async read() {
      try {
        const key = LocalStorage.key();
        const value = localStorage.getItem(key);
        return value == null ? undefined : value;
      } catch {
        return undefined;
      }
    },
    async write(newValue: string) {
      const key = LocalStorage.key();
      if (newValue !== localStorage.getItem(key)) {
        localStorage.setItem(key, newValue);
      }
    },
  };
  const Storage = {
    key() {
      return STORAGE_KEY;
    },
    async read() {
      const key = Storage.key();
      const { value } = await Preferences.get({ key });
      return value ?? undefined;
    },
    async write(newValue: string) {
      const key = Storage.key();
      await Preferences.set({ key, value: newValue });
    },
  };

  // Getters
  const authenticated = computed(() => token.value.client != null && token.value.client !== '');

  // Actions
  async function initialize() {
    if (initialized == null) {
      // Start watching storage
      watch(token, onTokenChange);

      globalThis.addEventListener('storage', (event) => {
        if (event.key === STORAGE_KEY) {
          void onLocalStorageChange();
        }
      });

      initialized = loadStorage();
    }
    await initialized;
  }

  async function save(authToken: AuthData) {
    await initialize();
    token.value = authToken;
  }

  async function clear() {
    await initialize();
    token.value = defaultToken;
  }

  async function refresh(accessToken: string) {
    await initialize();
    token.value = { ...token.value, accessToken };
  }

  async function loadStorage() {
    try {
      const value = await Storage.read();
      const decoded = value == null ? defaultToken : Codec.parse(value);
      token.value = decoded;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
      token.value = defaultToken;
    }
  }

  async function onTokenChange() {
    const { value } = token;
    const valueString = Codec.stringify(value);

    // Write in both storages
    await LocalStorage.write(valueString);
    await Storage.write(valueString);
  }

  async function onLocalStorageChange() {
    const value = await LocalStorage.read();
    const decoded = value == null ? defaultToken : Codec.parse(value);
    token.value = decoded;
  }

  return {
    token,
    authenticated,
    save,
    clear,
    initialize,
    refresh,
  };
});
