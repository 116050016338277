<script setup lang="ts">
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { storeToRefs } from 'pinia';
import { AppMenu } from '@/components/AppMenu';
import { useTokenStore } from '@/store/token';

const storeToken = useTokenStore();
const { authenticated } = storeToRefs(storeToken);
</script>

<template>
  <ion-app>
    <ion-split-pane content-id="main-content">
      <AppMenu v-if="authenticated" />
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>
